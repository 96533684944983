import { FC } from 'react';
import { Poppins as GoogleFont } from 'next/font/google';

export const poppins = GoogleFont({
    weight: ['400', '600', '700'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

export const Poppins: FC<{}> = () => (
    <style
        key="fontfaces-poppins"
        className={poppins.className}
        dangerouslySetInnerHTML={{
            __html: `
                :root {
                    --font-family-poppins: Poppins, ${poppins.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
        }}
    />
);
